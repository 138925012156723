import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StorageType } from 'src/app/common/enum/storage.enum';
import { HeaderStatusService } from 'src/app/common/services/header-status.service';
import { StorageService } from 'src/app/common/services/storage.service';
// import { ValidationConstant } from 'src/app/common/validation/validation.constant';
// import { Validator } from 'src/app/common/validation/validator';
// import { PaymentInformationService } from '../../services/payment-information.service';
import { SendEmailComponent } from '../send-email/send-email.component';
import { TransactionStatusMapEnum } from 'src/app/common/enum/transaction-status-map.enum';
import { AdditionalFieldsMapEnum } from 'src/app/common/enum/additional-fields-map.enum';
import { CommonService } from 'src/app/common/services/common.service';


@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss'],
})
export class PaymentSummaryComponent implements OnInit {
  bsModalRef: BsModalRef;
  paymentSummary: any = {};
  AdditionalData: any;
  transactionSummary: any;
  agentSettingsData: any;
  loader: boolean = true;
  modalRef: BsModalRef;
  additionalFieldsData:any=[];
  fullAddress: any = {};
  ISDCode: any;
  merchantISDCode: any;

  constructor(
    private modalService: BsModalService,
    private headerService: HeaderStatusService,
    private commonService: CommonService,
    private storageService: StorageService,
  ) {}
  public printConfig = {
    printMode: 'template-popup',
    popupProperties:
      'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    pageTitle: 'Transaction Receipt',
    styles: [
      `@media print {
      body {-webkit-print-color-adjust: exact; color-adjust: exact !important;height: auto;
        height:90vh;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden; background:white !important}
      }

      `,
    ],
  };
  ngOnInit(): void {
    let sessiontransactionSummary = this.storageService.get(
      StorageType.session,
      'transactionSummary'
    );
    // this.transactionSummary = JSON.parse(sessiontransactionSummary || '{ }');
    this.transactionSummary = sessiontransactionSummary ? JSON.parse(sessiontransactionSummary) : {};

    let agentSettingsData = this.storageService.get(
      StorageType.session,
      'agentSettings'
    );
    // this.agentSettingsData = JSON.parse(agentSettingsData || '{ }');
    this.agentSettingsData = agentSettingsData ? JSON.parse(agentSettingsData) : {};
    this.merchantISDCode = this.agentSettingsData?.transactionSummary?.isdCode
    if(this.merchantISDCode){
    if(this.merchantISDCode.startsWith('00')){
      this.agentSettingsData.transactionSummary.isdCode = `+${this.merchantISDCode.substring(2)}`;
    }
    else{
      this.agentSettingsData.transactionSummary.isdCode = this.merchantISDCode
    }
  }
    // this.fullAddress = this.commonService.getFullAddress(JSON.parse(JSON.stringify(this.agentSettingsData?.merchantDetail?.address)), []);
    this.fullAddress = this.agentSettingsData?.merchantDetail?.address ? 
    this.commonService.getFullAddress(JSON.parse(JSON.stringify(this.agentSettingsData?.merchantDetail?.address)),[]) : null;
    this.headerService.castHeaderStatus.subscribe((data) => {
      let serviceData = data;
      this.paymentSummary = serviceData['paymentSummary'];
      this.ISDCode = this.paymentSummary?.billingContact?.isdCode
      if(this.ISDCode){ 
      if(this.ISDCode.startsWith('00')){
        this.paymentSummary.billingContact.isdCode = `+${this.ISDCode.substring(2)}`;
      }
      else{
        this.paymentSummary.billingContact.isdCode = this.ISDCode
      }
    }
      this.AdditionalData = JSON.parse(this.paymentSummary.additionalFields);
      if (this.paymentSummary?.additionalFields != null) {
        var additionalDetails = JSON.parse(this.paymentSummary?.additionalFields) || {};
        Object.keys(additionalDetails).every(key => {
          if (key == 'shoppercompanyname') {
            return false;
          }
          
          if (additionalDetails[key] != "" && additionalDetails[key] != null) {
            this.paymentSummary.referenceValue = additionalDetails[key];
            this.paymentSummary.referenceLable = AdditionalFieldsMapEnum[key];
            let obj={
              referenceValue:additionalDetails[key],
              referenceLable : AdditionalFieldsMapEnum[key]
            }
              this.additionalFieldsData.push(obj);
          }
          return true;
        });    
      }
    });
    this.loader = false;
  }

  // open send email modal

  showModalPopup(template: TemplateRef<any>) {
    const config = {
      backdrop: false,
      ignoreBackdropClick: true,
      class: 'modal-lg modal-dialog-centered receiptEmail',
    };
    this.bsModalRef = this.modalService.show(SendEmailComponent, config);
  }

  openModal(template: TemplateRef<any>) {
    const config = {
      backdrop: false,
      ignoreBackdropClick: true,
      class: 'modal-lg receiptSummary',
    };
    this.modalRef = this.modalService.show(template,config);
}


closeModel() {
  this.modalRef?.hide();
}

}
