import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StorageType } from 'src/app/common/enum/storage.enum';
import { CommonService } from 'src/app/common/services/common.service';
import { StorageService } from 'src/app/common/services/storage.service';
import { LoadingService } from '../../common/services/loading.service';
import { SettingsService } from './payment/services/settings.service';
import { FeatureAccessEnum, FeaturesEnum } from 'src/app/common/enum/features.enum';
import { AppSetting } from 'src/app/common/constants/appsetting.constant';
import { LaunchDarklyService } from './payment/services/launch-darkly.service';

@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.scss'],
})
export class SecureComponent implements OnInit {
  title = 'anytimepayment-form';
  // ref!: ErrorComponent;
  loader = true;
  invalidMerchant: boolean = false;
  inactiveMerchant: boolean = false;
  emptySubdomain: boolean = false;

  constructor(
    private loading: LoadingService,
    private http: HttpClient,
    private settingsService: SettingsService,
    private commonService: CommonService,
    private loadingService: LoadingService,
    private storageService: StorageService,
    private router: Router, // private toastr: ToastrService // private errorService: ErrorService,
    private launchDarklyService:LaunchDarklyService
  ) {
   
  }

  ngOnInit(): void {
    this.getSettings();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.location.reload();
      }
    });
  }

  getSettings() {
    let path = window.location.pathname.replace('/', '');
    let urlParams = path.split('/');
    let requestOptions: any;

    if (window.location.pathname == '/') {
      path = window.location.hash;
      urlParams = path.split('/');
    }

    if (urlParams.length != 2) {
      this.loader = false;
      // let errormsg = 'notfound';
      this.router.navigate(['/not-found']);
      return;
    }
    if (urlParams[0] == '#') {
      this.storageService.save(StorageType.session, 'id', urlParams[1]);
      requestOptions = {
        Token: urlParams[1],
        Type: AppSetting.paymentFormType
      };
      this.appSettingsAPI(requestOptions);
    } else {
      this.storageService.save(StorageType.session, 'id', urlParams[0]);
      requestOptions = {
        Token: urlParams[0],
        Type: AppSetting.paymentFormType
      };
      this.appSettingsAPI(requestOptions);
    }
  }

  appSettingsAPI(requestOptions: any) {
    this.settingsService.settings(requestOptions).subscribe({
      next: (res:any) => {

        /* error message handling variable & storage */
        const phone = (res?.transactionSummary) ? (res?.transactionSummary?.mobileNumber) ? (res?.transactionSummary?.mobileNumber) : '' : '';
        const email = (res?.transactionSummary) ? (res?.transactionSummary?.email) ? (res?.transactionSummary?.email) : '' : '';
        this.storageService.save(StorageType.session, 'dbName', (res.merchantDetail.dbaName) ? res.merchantDetail.dbaName : '');
        this.storageService.save(StorageType.session, 'merchantPhone', (phone) ? phone :  '');
        this.storageService.save(StorageType.session, 'merchantEmail', (email) ? email : '');
        const paymentAccLimits = res?.paymentAcceptanceLimit
        this.storageService.save(
          StorageType.session,
          'paymentAcceptanceLimit',
          JSON.stringify(paymentAccLimits)
        );
        this.storageService.save(StorageType.session, 'settingsData', JSON.stringify(res))
        this.launchDarklyService.initLDClient(AppSetting.lanuchDarklyClientKey);
        this.featureAPI(res);
      },
      error: (_e) => {
        if (_e.error.message == 'Key_InvalidFields') {
          this.emptySubdomain = true;
          this.invalidMerchant = false;
          this.inactiveMerchant = false;
          this.loader = false;
        }
        if (_e.error.message == 'Key_InvalidMerchant') {
          this.invalidMerchant = true;
          this.emptySubdomain = false;
          this.inactiveMerchant = false;
          this.loader = false;
          // this.router.navigate(['/access-denied']);
        }
        if (_e.error.message == 'Key_MerchantInActive') {
          this.invalidMerchant = false;
          this.emptySubdomain = false;
          this.inactiveMerchant = true;
          this.loader = false;
        }
      },
    });
  }

  featureSupress(data) {
    let requestOptions = {
      reqObj : {
        Token: data.token,
        Type: AppSetting.paymentFormType
      },
      featureId: FeatureAccessEnum[FeaturesEnum.F08]
    };
    this.settingsService.features(requestOptions).subscribe({
      next: (res: any) => {
        if (!res.hasAccess) {
          this.loadPaymentForm(data);
          this.commonService.getAllCountryList();
          this.commonService.getStateList();
        } else {
          this.loader = false;
          this.router.navigate(['/access-denied']);
        }
      },
      error: (e) => {
        if (e.error.message == 'Key_MerchantFeatureNotFound') {
          this.loadPaymentForm(data);
          this.commonService.getAllCountryList();
          this.commonService.getStateList();
        }
      },
    });
  }
  //Get 'AnytimePayments' feature details and check is Enabled for this merchant
  featureAPI(data) {
    let requestOptions = {
      reqObj : {
        Token: data.token,
        Type: AppSetting.paymentFormType
      },
      featureId: FeatureAccessEnum[FeaturesEnum.F02]
    };
    this.settingsService.features(requestOptions).subscribe({
      next: (res: any) => {
        if (res.hasAccess) {
          this.featureSupress(data);
        } else {
          this.loader = false;
          this.router.navigate(['/access-denied']);
        }
      },
      error: (e) => {
        if (e.error.message == 'Key_MerchantFeatureNotFound') {
          this.router.navigate(['/access-denied']);
        }
      },
    });
  }

  async loadPaymentForm(data: any) {
    let merchantBranding = data.merchantBranding;
    this.changeTheme(
      merchantBranding.primaryColor,
      merchantBranding.secondaryColor,
      merchantBranding.primaryFontColor,
      merchantBranding.secondaryFontColor,
      merchantBranding.backgroundColor
    );
    this.loader = false;
    this.storageService.save(
      StorageType.session,
      'agentSettings',
      JSON.stringify(data)
    );

    if (data.transactionSummary != null) {
      this.storageService.save(
        StorageType.session,
        'transactionSummary',
        JSON.stringify(data.transactionSummary)
      );
    } else {
      let transactionSummaryjSON = {
        title: 'AnytimePayment',
        mobileNumber: ' ',
        companyName: ' ',
        description: ' ',
        email: ' ',
      };
      this.storageService.save(
        StorageType.session,
        'transactionSummary',
        JSON.stringify(transactionSummaryjSON)
      );
    }
  }
  hexToRgb = (hex) =>
    hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (_m, r, g, b) => '#' + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));

  changeTheme(
    primary: string,
    secondary: string,
    primary_font: any,
    secondary_font: any,
    custom_bg: string
  ) {
    if (primary != null && primary != '') {
      document.documentElement.style.setProperty(
        '--primary',
        this.hexToRgb(primary)
      );
    }
    if (primary_font != null && primary_font != '') {
      document.documentElement.style.setProperty(
        '--font-primary',
        this.hexToRgb(primary_font)
      );
    }
    if (secondary != null && secondary != '') {
      document.documentElement.style.setProperty(
        '--secondary',
        this.hexToRgb(secondary)
      );
    }
    if (secondary_font != null && secondary_font != '') {
      document.documentElement.style.setProperty(
        '--font-secondary',
        this.hexToRgb(secondary_font)
      );
    }
    if (custom_bg != null && custom_bg != '') {
      document.documentElement.style.setProperty(
        '--background-color',
        this.hexToRgb(custom_bg)
      );
    }
  }
}
