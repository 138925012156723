import { EventEmitter, Injectable } from '@angular/core';
import { initialize } from 'launchdarkly-js-client-sdk';
import { AppSetting } from 'src/app/common/constants/appsetting.constant';
import { StorageType } from 'src/app/common/enum/storage.enum';
import { CommonService } from 'src/app/common/services/common.service';
import { StorageService } from 'src/app/common/services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LaunchDarklyService {
  lanuchDarklyClient: any
  launchDarklyAllFlags:any;
  launchDarklyEmitter= new EventEmitter();
  settingsData: any;
  constructor(
    private storageService: StorageService,
    private commonService: CommonService
  ) { }

  getSettingsData() {
    return JSON.parse(
      this.storageService.get(StorageType.session, 'settingsData')
    );
  }

  // initialize lanuch Darkly Client
  initLDClient(lanuchDarklyClientKey) {
    try {
      this.settingsData = this.getSettingsData();
      let context: any
      if (this.settingsData != null) {
        context = {
          key: this.settingsData?.paymentAdjustments[0]?.merchantId || '',
          name: this.settingsData?.name,
          anonymous: false,
        };
      } else {
        context = {
          key: "AdminUser",
          name: "AdminUser",
          anonymous: false,
        };
      }
  
      // Create a custom logger
      const logger = {
        debug: () => {},
        info: () => {},
        warn: () => {},
        error: console.error, // Only log errors to the console
      };
  
      // Initialize LaunchDarkly client with custom logger
      this.lanuchDarklyClient = initialize(lanuchDarklyClientKey, context, { logger });
      this.lanuchDarklyClient.on('ready', () => {
        this.identifyUser(context);
        this.getAllFeatureFlags();
      });
      this.lanuchDarklyClient.on('change', (flags) => {
        this.getAllFeatureFlags();
      });
  
    } catch (error) {
      // console.error("initLDClient error:", error);
    }
  }
  // register user under context 
  identifyUser(config) {
    this.lanuchDarklyClient.identify(config).then((data) => {
      this.lanuchDarklyClient.flush();
    });
  }
  getAllFeatureFlags() {
    this.launchDarklyAllFlags = this.lanuchDarklyClient.allFlags();
    this.launchDarklyEmitter.emit(this.launchDarklyAllFlags)    
  }

  getFeatureFalg(flagName) {
    if (this.lanuchDarklyClient != undefined)
      return this.lanuchDarklyClient.variation(flagName, false);
    else {
      this.initLDClient(AppSetting.lanuchDarklyClientKey);
    }
  }
}
